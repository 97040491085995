export default {
  upsale: {
    rows: [{
      id: 1,
      order_id: 1,
      order_code: 'O-0001',
      customer_id: 1,
      customer_name: 'นางเอียด',
      store_id: 1,
      store_name: 'หรีด ณ วัด',
      date_order: '21/08/2020',
      sale_id: 1,
      sale_name: 'จำปา',
      total: 600,
      status: 1,
      status_name: 'รอการตรวจสอบ',
      products: [{
        product_code: 'B124',
        product_name: 'บุษปวัน',
        upsale: true,
        price: 300,
        cost: 300
      }, {
        product_code: 'A215',
        product_name: 'กุหลาบ',
        upsale: true,
        price: 300,
        cost: 300
      }, {
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        upsale: true,
        price: 300,
        cost: 300
      }]
    }, {
      id: 2,
      order_id: 2,
      order_code: 'O-0002',
      customer_id: 1,
      customer_name: 'นางสมบัติ',
      store_id: 1,
      store_name: 'หรีด ณ วัด',
      date_order: '21/08/2020',
      sale_id: 1,
      sale_name: 'จำปา',
      total: 400,
      status: 2,
      status_name: 'ตรวจสอบแล้ว',
      products: [{
        product_code: 'B124',
        product_name: 'บุษปวัน',
        upsale: true,
        price: 300,
        cost: 150
      }, {
        product_code: 'A215',
        product_name: 'กุหลาบ',
        upsale: true,
        price: 300,
        cost: 150
      }, {
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        upsale: true,
        price: 300,
        cost: 100
      }]
    }, {
      id: 3,
      order_id: 10,
      order_code: 'O-0003',
      customer_id: 5,
      customer_name: 'นางไฉไล',
      store_id: 1,
      store_name: 'หรีด ณ วัด',
      date_order: '21/08/2020',
      sale_id: 2,
      sale_name: 'พุทธา',
      total: 800,
      status: 1,
      status_name: 'รอการตรวจสอบ',
      products: [{
        product_code: 'B124',
        product_name: 'บุษปวัน',
        upsale: true,
        price: 300,
        cost: 300
      }, {
        product_code: 'A215',
        product_name: 'กุหลาบ',
        upsale: true,
        price: 300,
        cost: 300
      }, {
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        upsale: true,
        price: 300,
        cost: 200
      }]
    }],
    paging: {
      rowTotal: 1
    }
  },
  search_filters: {
    customer_filters: [{
      filter_count: 0,
      id: 1,
      name: 'นางเอียด'
    }, {
      filter_count: 0,
      id: 2,
      name: 'นางไฉไล'
    }]
  },
  status_upsales: [{
    id: 1,
    name: 'รอการตรวจสอบ'
  }, {
    id: 2,
    name: 'ตรวจสอบแล้ว'
  }]
}
