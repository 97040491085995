<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-user-lock"></i> ข้อมูลสินค้า </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 mb-2"><strong>รหัสสั่งซื้อ :</strong></div>
                <div class="col-sm-10">{{item.order_code}}</div>
                <div class="col-sm-2 mb-2"><strong>ชื่อผู้ขาย :</strong></div>
                <div class="col-sm-10">{{item.sale_name}}</div>
                <div class="col-sm-2 mb-2"><strong>ชื่อลูกค้า :</strong></div>
                <div class="col-sm-10">{{item.customer_name}}</div>
              </div>
              <div class="row">
                <div class="col-sm-2 mb-2"><strong>เริ่มต้น :</strong></div>
                <div class="col-sm-10">{{order.upsell_start | number-decimal-format}}</div>
                <div class="col-sm-2 mb-2"><strong>Upsell :</strong></div>
                <div class="col-sm-10">{{order.upsell_addon | number-decimal-format}}</div>
                <div class="col-sm-2 mb-2"><strong>รายละเอียด :</strong></div>
                <div class="col-sm-10">{{order.upsell_remark}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                      <tr class="text-center" >
                        <th>รหัสสินค้า</th>
                        <th>ชื่อสินค้า</th>
                        <th>ต้นทุน</th>
                        <th>ราคาสินค้า</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in item.products" :key="`list_mapping_product_preview_${index}`">
                      <td class="text-center">{{data.product_code}}</td>
                      <td class="text-center">{{data.product_name}}</td>
                      <td class="text-right">{{data.cost | number-decimal-format}}</td>
                      <td class="text-right">{{data.price | number-decimal-format}}</td>
                    </tr>
                    <tr v-if="item.products.length === 0" class="text-center">
                      <td colspan="4">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="float-right pt-5 d-flex">
            <span><router-link :to="'/upsale'" class="nav-link text-underline"> กลับ</router-link></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import mock from '../mock/upsale';
  export default {
    name: 'upsale-view-page-product',
    data () {
      return {
        permission:{
          appSlug: 'upsale',
          actionData: {}
        },
        id: '',
        mock: mock,
        order: {

        },
        item: {
          id: '',
          order_id: '',
          order_code: '',
          customer_id: '',
          customer_name: '',
          store_id: '',
          store_name: '',
          date_order: '',
          sale_id: '',
          sale_name: '',
          total: '',
          products: []
        }
      }
    },
    methods: {
      async getItem (id) {
        const result = await this.HttpServices.getData(`/ab_upsale/${this.id}`);
        if(result&&result.status.code=="200"){
          this.item = result.data;
          const order = await this.HttpServices.getData(`/ab_order/${this.item.order_id}`);
          if(order&&order.status.code=="200"){
            this.order = order.data;
          }
        }else{
          this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลอัพเซล`, ()=>{
            this.$router.push('/upsale');
          });
        }
      }
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      this.id = await this.SessionStorageUtils.getSession("page-upsale");
      await this.getItem();
    }
  };
</script>
